import BottomSheet from 'components/BottomSheet';
import Icon, { Loader } from 'components/Icon';
import SkeletonLoader from 'components/SkeletonLoader';
import { usePhotoChoices } from 'queries/photos';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import { find } from 'lodash';
import Button, { Size, Variants } from 'components/Button';
import PhotoCard from './PhotoCard';

type AppProps = {
  open: boolean;
  closeModal: (...args: any) => any;
  selectedPhotos: any[];
  onSelect: (...args: any) => any;
  q?: Record<string, any>;
  submitMutation?: any;
  limit?: number;
};

const PhotoSelectorModal: React.FC<AppProps> = ({
  open,
  closeModal,
  selectedPhotos,
  onSelect,
  q = {},
  submitMutation = null,
  limit,
}) => {
  const { isMobile } = useMedia();
  const { ref, inView } = useInView();

  const { status, data, isFetching, isFetchingNextPage, fetchNextPage } =
    usePhotoChoices(q);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const handleSelect = (photo: any) => {
    if (find(selectedPhotos, { value: photo.value })) {
      onSelect(selectedPhotos.filter((i: any) => i.value !== photo.value));
    } else {
      if (limit && selectedPhotos.length >= limit) {
        const _selectedPhotos = selectedPhotos;
        _selectedPhotos.splice(0, 1);
        onSelect([..._selectedPhotos, photo]);
        // return toast.error(`You can only select ${limit} photos`);
      } else {
        onSelect([...selectedPhotos, photo]);
      }
    }
  };

  const totalCount = data?.pages[0]?.count;

  const renderFooter = () => {
    return (
      <div
        className={clx(
          'bg-white px-2 flex items-center justify-end space-x-4',
          { 'pt-4': isMobile, 'py-2 mt-6': !isMobile }
        )}
      >
        <div>
          <Button
            label="Cancel"
            variant={Variants.Outline}
            size={Size.Medium}
            onClick={() => {
              onSelect([]);
              closeModal(true);
            }}
          />
        </div>
        <div>
          <Button
            label="Confirm"
            size={Size.Medium}
            onClick={async () => {
              onSelect(selectedPhotos);
              if (totalCount && submitMutation) {
                await submitMutation?.mutateAsync();
              }
              closeModal();
            }}
            loading={submitMutation?.isLoading}
          />
        </div>
      </div>
    );
  };

  return (
    <BottomSheet maxWidth="max-w-3xl" open={open}>
      <div className="relative">
        <div className="pb-2">
          <div className="flex justify-between">
            <div className="text-xl font-bold mb-2">Select Job Photos</div>
            <div>
              <Icon
                name="close"
                size={32}
                onClick={closeModal}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div>
          {(() => {
            if (status === 'loading') {
              return (
                <div className="flex justify-center">
                  <SkeletonLoader
                    grid={
                      isMobile
                        ? { size: { height: 120, width: 148 }, count: 2 }
                        : { size: { height: 112, width: 140 }, count: 3 }
                    }
                    size={isMobile ? 3 : 4}
                  />
                </div>
              );
            }

            if (!totalCount) {
              return <div>No photos to select</div>;
            }

            return null;
          })()}
        </div>
        {!!selectedPhotos.length && (
          <div className="apart">
            <div className="mb-4 text-xs text-text-medium">
              Selected <b>{selectedPhotos.length}</b> Photos
            </div>
            <div
              className="text-primary text-xs hover:underline cursor-pointer"
              onClick={() => onSelect([])}
            >
              Clear Selection
            </div>
          </div>
        )}
        <div
          className={clx(' overflow-y-auto', {
            'max-h-[65vh]': isMobile,
            'max-h-[45vh]': !isMobile,
          })}
        >
          {!!totalCount && (
            <>
              <div
                className={clx('grid gap-2', {
                  'grid-cols-2': isMobile,
                  'grid-cols-4': !isMobile,
                })}
              >
                {data?.pages?.map((page: any) => (
                  <React.Fragment key={page.next_page}>
                    {page.results.map((photo: any) => (
                      <PhotoCard
                        key={photo.value}
                        photo={photo}
                        selectedPhotos={selectedPhotos}
                        handleSelect={handleSelect}
                      />
                    ))}
                  </React.Fragment>
                ))}
                {!isFetchingNextPage && <div ref={ref} />}
              </div>
              <div className="py-2 w-full flex justify-center">
                {isFetching && <Loader />}
              </div>
            </>
          )}
        </div>

        <div className={clx({ 'sticky -bottom-6': !isMobile })}>
          {renderFooter()}
        </div>
      </div>
    </BottomSheet>
  );
};

export default PhotoSelectorModal;
