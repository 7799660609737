import React, { useMemo } from 'react';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import useModal from 'hooks/useModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteDocument } from 'queries/documents';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { useParams } from 'react-router-dom';
import Confirmation from 'components/Confirmation';
import useMedia from 'hooks/useMedia';
import EditDoc from './EditDoc';

type AppProps = {
  doc: Record<string, any>;
};

const Actions: React.FC<AppProps> = ({ doc }) => {
  const { jobId = '' } = useParams();
  const [edit, showEdit, closeEdit] = useModal();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const queryClient = useQueryClient();
  const { isMobile } = useMedia();

  const deleteDocumentMutation = useMutation(() => deleteDocument(doc.id), {
    onError: (e: any) => {
      toast.error(readAxiosErr(e));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['job-checklist', jobId]);
      queryClient.invalidateQueries(['job-last-checklist', jobId]);
      queryClient.invalidateQueries(['documents']);
      queryClient.invalidateQueries(['all-documents', { job: jobId }]);
    },
  });

  const options = useMemo(() => {
    let _options: any[] = [
      {
        key: 'edit',
        label: 'Edit',
        icon: 'edit',
        iconClass: 'text-primary',
        onClick: showEdit,
      },
    ];

    if (doc.is_active) {
      _options = [
        ..._options,
        {
          key: 'download',
          label: 'Download',
          icon: 'cloud-download',
          iconClass: 'text-primary',
          onClick: () => {
            window.open(doc.url, '_blank');
          },
          element: (styles: any) => (
            <div
              className={styles}
              onClick={(e) => {
                e.preventDefault();
                window.open(doc.url, '_blank');
              }}
            >
              Download
            </div>
          ),
        },
      ];
    }

    _options = [
      ..._options,
      {
        key: 'delete',
        label: 'Delete',
        icon: 'delete',
        iconClass: 'text-error',
        onClick: showConfirmModal,
      },
    ];
    return _options;
  }, [doc.is_active]);

  return (
    <>
      {isMobile ? (
        <Menu
          trigger={<Icon name="more" size={16} className="mt-[4px]" />}
          options={options}
        />
      ) : (
        <div className="v-center space-x-2">
          {options?.map((option) => (
            <div key={option.key}>
              <Icon
                name={option.icon}
                className={`${option.iconClass} cursor-pointer`}
                onClick={option.onClick}
                size={18}
              />
            </div>
          ))}
        </div>
      )}
      <Confirmation
        title="Delete Document?"
        message="Are you sure you want to delete this document? This can not be undone."
        open={confirmOpen}
        closeConfirm={closeConfirmModal}
        onClick={() => deleteDocumentMutation.mutate()}
        isLoading={deleteDocumentMutation.isLoading}
      />
      <EditDoc open={edit} onClose={closeEdit} doc={doc} />
    </>
  );
};

export default Actions;
