import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Inspections For Job *********
export const getInspections = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/inspections/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useInspections = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['inspections', q],
    ({ pageParam }) => getInspections({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopInspections = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['desktop-inspections', q, { page }],
    () => getInspections({ pageParam: page, ...q }),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

// ********* Get Inspection Detail *********
export const getInspectionDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/inspections/${id}/`);
  return data;
};

export const useInspectionDetail = (id: string) => {
  return useQuery(['inspection-detail', id], () => getInspectionDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

const getInspectionFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/inspections/fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  inspection?: string;
}

export const useInspectionFieldConfig = (q: IFieldConfig = {}) => {
  const { job, inspection } = q;
  return useQuery(
    ['inspection-field-config', { job, inspection }],
    () => getInspectionFieldConfig({ job, inspection }),
    {
      staleTime: inspection ? 0 : Infinity,
    }
  );
};

// ********* Create Inspection *********
export const createInspection = async (
  job: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/inspections/?job=${job}`,
    payload
  );
  return data;
};

export const updateInspection = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/inspections/${id}/`,
    payload
  );
  return data;
};

export const deleteInspection = async (id: string) => {
  await apiService.delete(`/api/light/inspections/${id}/`);
};

// ******* lineitems related queries ******
interface ILineitemFieldConfig {
  inspection?: string;
  lineitem?: string;
}

const getLineItemFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/inspection-lineitems/fields_config/`,
    q
  );
  return data;
};

export const useLineItemFieldConfig = (q: ILineitemFieldConfig = {}) => {
  const { inspection, lineitem } = q;
  return useQuery(
    ['inspection-lineitems-field-config', { inspection, lineitem }],
    () => getLineItemFieldConfig({ inspection, lineitem }),
    {
      staleTime: lineitem ? 0 : Infinity,
    }
  );
};

const getLineItemChoices = async (lineId: string) => {
  const { data } = await apiService.get(
    `/api/light/inspection-lineitems/${lineId}/choices/`
  );
  return data;
};

export const useLineItemChoices = (lineId: string) => {
  return useQuery(
    ['inspection-lineitems-choices', lineId],
    () => getLineItemChoices(lineId),
    {
      staleTime: Infinity,
    }
  );
};

export const createLineitem = async (
  inspection: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/inspection-lineitems/?inspection=${inspection}`,
    payload
  );
  return data;
};

export const updateLineitem = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/inspection-lineitems/${id}/`,
    payload
  );
  return data;
};

export const deleteLineitem = async (id: string) => {
  await apiService.delete(`/api/light/inspection-lineitems/${id}/`);
};

export const markAsComplete = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/inspections/${id}/complete/`
  );
  return data;
};

export const markAsInComplete = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/inspections/${id}/incomplete/`
  );
  return data;
};

export const saveToDocs = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/inspections/${id}/save_to_docs/`
  );
  return data;
};

export const emailToCustomer = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/inspections/${id}/email_to_customer/`
  );
  return data;
};

export const relateInspectionLineItemPhotos = async (
  id: string,
  photos: string[]
) => {
  const { data } = await apiService.patch(
    `/api/light/inspection-lineitems/${id}/relate_photos/`,
    { photos }
  );
  return data;
};
