import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Layout } from 'components/Form';
import { FieldType } from 'utils/enum';
import { useDocumentTagChoices } from 'queries/company';
import Button from 'components/Button';
import { readAxiosErr } from 'utils/misc';
import { updateDocument } from 'queries/documents';
import toast from 'react-hot-toast';

type AppProps = {
  open: boolean;
  onClose: () => void;
  doc: Record<string, any>;
};

interface IFormValues {
  name: string;
  tags: string[];
}

const EditDoc: React.FC<AppProps> = ({ open, onClose, doc }) => {
  const queryClient = useQueryClient();

  const updatemutation = useMutation(
    (payload: any) => updateDocument(doc?.id, payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        onClose();
      },
    }
  );

  const schema = yup.object({
    name: yup.string().required(),
    tags: yup.array().of(yup.string()),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: doc?.name,
      tags: doc?.tags,
    },
  });

  const fields = [
    {
      type: FieldType.Input,
      label: 'Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      name: 'tags',
      type: FieldType.AsyncMultiSelect,
      label: 'Tags',
      // @ts-ignore
      error: errors.tags?.message,
      control,
      extraOptions: doc?.tags?.map((t: any) => ({
        label: t,
        value: t,
      })),
      optionsPromise: useDocumentTagChoices,
    },
  ];

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    updatemutation.mutate(formData);
  };

  return (
    <BottomSheet open={open} closeSheet={onClose}>
      <div>
        <div className="apart">
          <div className="page-heading">Update Document</div>
          <Icon name="close" size={32} onClick={onClose} />
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Layout fields={fields} loading={updatemutation.isLoading} />

            <Button
              label="Update"
              className="mt-8"
              loading={updatemutation.isLoading}
            />
          </form>
        </div>
      </div>
    </BottomSheet>
  );
};

export default EditDoc;
