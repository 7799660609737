import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';
import apiService from 'utils/apiService';
import { serverToUnix } from 'utils/time';
import { getEmails } from './emails';
import { getEvents } from './events';
import { getNotes } from './notes';
import { getSmses } from './sms';
import { getJobTodos } from './todos';

// ********** Get jobs **************
const getJobs = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/jobs/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const getApprovedJobs = async ({ pageParam = 1, filters = {} }) => {
  const { data } = await apiService.get('/api/light/jobs/approved/', {
    page: pageParam,
    ...filters,
  });
  return data;
};

export const getApprovedJobsStats = async () => {
  const { data } = await apiService.get('/api/light/jobs/approved/stats/');
  return data;
};

export const getProspectJobs = async ({ pageParam = 1, filters = {} }) => {
  const { data } = await apiService.get('/api/light/jobs/prospect/', {
    page: pageParam,
    ...filters,
  });
  return data;
};

export const getProspectJobsStats = async () => {
  const { data } = await apiService.get('/api/light/jobs/prospect/stats/');
  return data;
};

export const getRecentJobs = async () => {
  const { data } = await apiService.get('/api/light/jobs/recent/');
  return data;
};

export const getStarredJobs = async ({ pageParam = 1 }) => {
  const { data } = await apiService.get('/api/light/jobs/starred/', {
    page: pageParam,
  });
  return data;
};

export const useJobs = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['jobs', q],
    ({ pageParam }) => getJobs({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useApprovedJobs = () => {
  return useInfiniteQuery(
    ['jobs', 'approved'],
    ({ pageParam }) =>
      getApprovedJobs({
        pageParam,
        filters: {
          date_closed__isnull: true,
          date_deleted__isnull: true,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useProspectJobs = () => {
  return useInfiniteQuery(
    ['jobs', 'prospect'],
    ({ pageParam }) =>
      getProspectJobs({
        pageParam,
        filters: {
          date_deleted__isnull: true,
        },
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

const getJobReport = async ({ pageParam = 1, filters = {} }) => {
  const { data } = await apiService.get('/api/light/job-report/', {
    page: pageParam,
    ...filters,
  });
  return data;
};

export const useJobReport = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['job-report', q],
    ({ pageParam }) => getJobReport({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useProspectDesktopJobs = (page = 1, filters = {}) => {
  return useQuery(
    ['jobs', 'prospect', 'desktop', { page, ...filters }],
    () => getProspectJobs({ pageParam: page, filters }),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const useProspectJobsStats = () => {
  return useQuery(['jobs', 'stats', 'prospect'], getProspectJobsStats, {
    staleTime: 5 * 60 * 1000,
  });
};

export const useApprovedJobsStats = () => {
  return useQuery(['jobs', 'stats', 'approved'], getApprovedJobsStats, {
    staleTime: 5 * 60 * 1000,
  });
};

export const useApprovedDesktopJobs = (page = 1, filters = {}) => {
  return useQuery(
    ['jobs', 'approve', 'desktop', { page, ...filters }],
    () => getApprovedJobs({ pageParam: page, filters }),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const useStarredJobs = () => {
  return useInfiniteQuery(['jobs', 'starred'], getStarredJobs, {
    getNextPageParam: (lastPage) => {
      return lastPage.next_page ?? undefined;
    },
    staleTime: 5 * 60 * 1000,
  });
};

export const useStarredDesktopJobs = (page = 1, enabled = true) => {
  return useQuery(
    ['jobs', 'starred', 'desktop', { page }],
    () => getStarredJobs({ pageParam: page }),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
      enabled,
    }
  );
};

export const useRecentJobs = (enabled = true) => {
  return useQuery(['jobs', 'recent'], getRecentJobs, {
    staleTime: 1 * 60 * 1000,
    enabled,
  });
};

export const useNearbyJobs = (
  { latitude = 0, longitude = 0 },
  page = 1,
  enabled = true
) => {
  return useQuery(
    ['jobs', 'nearby', latitude, longitude, page],
    () => getNearbyJobs({ latitude, longitude }, page),
    {
      staleTime: 1 * 60 * 1000,
      enabled,
    }
  );
};

export const getNearbyJobs = async (
  userLocation = { latitude: 0, longitude: 0 },
  page = 1
) => {
  if (!userLocation) {
    return [];
  }

  const { latitude, longitude } = userLocation;

  const { data } = await apiService.get(
    `/api/light/jobs/nearby/?location=${latitude},${longitude}&page=${page}`
  );
  return data;
};

// ********* Get extra job info **************
const getExtraJobInfo = async (id: number | string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/extra/`);
  return data;
};

export const useJobExtra = (jobId: number | string) => {
  return useQuery(['jobs', 'extra', jobId], () => getExtraJobInfo(jobId));
};

// ********* Get job details **********
const getMapJobDetail = async (id: number) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/map_detail/`);
  return data;
};

export const useMapJobDetail = (jobId: number) => {
  return useQuery(['job-detail', jobId, 'map'], () => getMapJobDetail(jobId), {
    staleTime: 10 * 60 * 1000,
  });
};

// ********** Get job details ************
const getJobDetail = async (id: number | string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/`);
  return data;
};

export const useJobDetail = (jobId: number | string, enabled = true) => {
  return useQuery(['job-detail', jobId], () => getJobDetail(jobId), {
    staleTime: 5 * 60 * 1000,
    enabled,
  });
};

export const getProspectPipeline = async (id: number) => {
  const { data } = await apiService.get(
    `/api/light/jobs/prospect/${id}/checklist/`
  );
  return data;
};

export const useProspectPipeline = (jobId: number) => {
  return useQuery(
    ['job-checklist', jobId?.toString(), 'prospect'],
    () => getProspectPipeline(jobId),
    {
      staleTime: 5 * 60 * 1000,
    }
  );
};

const getLastChecklistItem = async (id: string | number) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/last_completed_checklist/`
  );
  return data;
};

export const useJobLastChecklist = (jobId: string | number) => {
  return useQuery(
    ['job-last-checklist', jobId],
    () => getLastChecklistItem(jobId),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
};

const getQuickChecklistItems = async (id: string | number) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/quick_checklist/`
  );
  return data;
};

export const useProspectQuickChecklist = (jobId: string | number) => {
  return useQuery(
    ['job-last-checklist', jobId, 'quick'],
    () => getQuickChecklistItems(jobId),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
};

const getLastActivity = async (id: string | number) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/last_recent_activity/`
  );
  return data;
};

export const useJobLastActivity = (jobId: string | number) => {
  return useQuery(['job-last-activity', jobId], () => getLastActivity(jobId), {
    staleTime: 1 * 60 * 1000,
  });
};

const getLastNote = async (id: number | string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/last_note/`);
  return data;
};

export const useJobLastNote = (jobId: number | string) => {
  return useQuery(['job-last-note', jobId], () => getLastNote(jobId), {
    staleTime: 5 * 60 * 1000,
  });
};

const getMoreJobDetail = async (id: number | string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/more_detail/`);
  return data;
};

export const useMoreJobDetail = (jobId: number | string) => {
  return useQuery(
    ['job-detail', jobId, 'more'],
    () => getMoreJobDetail(jobId),
    {
      staleTime: 5 * 60 * 1000,
    }
  );
};

// ********* Get job stats ************
const getJobStats = async (id: string | number) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/stats/`);
  return data;
};

export const useJobStats = (jobId: string | number) => {
  return useQuery(['job-stats', jobId], () => getJobStats(jobId), {
    refetchInterval: 5 * 60 * 1000,
  });
};

// ********** Get prospect field config **********
const getProspectFieldConfig = async () => {
  const { data } = await apiService.get(
    `/api/light/jobs/prospect_fields_config/`
  );
  return data;
};

export const useProspectFieldConfig = () => {
  return useQuery(['field-config', 'prospect'], getProspectFieldConfig, {
    staleTime: Infinity,
  });
};

// ****** Create lead *******
export const createLead = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/jobs/', payload);
  return data;
};

export const addressOnlyLead = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/light/jobs/address_only_lead/',
    payload
  );
  return data;
};

// ******* Star/Unstar job ******
export const starJob = async (id: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${id}/star/`);
  return data;
};

export const unstarJob = async (id: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${id}/unstar/`);
  return data;
};

// ******* Job Contacts ******
export const getJobContacts = async (id: string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/contacts/`);
  return data;
};

export const useJobContacts = (jobId: string) => {
  return useQuery(['job-contacts', jobId], () => getJobContacts(jobId), {
    staleTime: 15 * 60 * 1000,
  });
};

// ******* Job checklist ******
export const getChecklist = async (id: string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/checklist/`);
  return data;
};

export const useProspectChecklist = (id: string) => {
  return useQuery(['job-checklist', id], () => getChecklist(id), {
    staleTime: 1 * 60 * 1000,
  });
};

export const getJobChecklist = async (id: string) => {
  const { data } = await apiService.get(`/api/light/jobs/${id}/job_checklist/`);
  return data;
};

export const useJobChecklist = (id: string) => {
  return useQuery(['job-checklist', id, 'job'], () => getJobChecklist(id), {
    staleTime: 1 * 60 * 1000,
  });
};

const getQuickJobChecklistItems = async (id: string | number) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/quick_job_checklist/`
  );
  return data;
};

export const useJobQuickChecklist = (jobId: string | number) => {
  return useQuery(
    ['job-last-checklist', jobId, 'quick', 'job'],
    () => getQuickJobChecklistItems(jobId),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
};

// ******* Job search ******
export const getSearch = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/api/light/jobs/search/', { ...q });
  return data;
};

export const useJobSearch = (q: Record<string, any>, enabled = true) => {
  return useQuery(['job-search', q], () => getSearch(q), {
    staleTime: 1 * 60 * 1000,
    enabled,
  });
};

// ***** Job recipients ********
const getNoteRecipients = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/note_recipients/`
  );
  return data;
};

export const useNoteRecipientsChoices = (jobId: string, enabled = true) => {
  return useQuery(
    ['job-note-recipients', jobId],
    () => getNoteRecipients(jobId),
    {
      enabled,
      staleTime: Infinity,
    }
  );
};

const getEmailRecipients = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/email_recipients/`
  );
  return data;
};

export const useEmailRecipientsChoices = (jobId: string, enabled = true) => {
  return useQuery(
    ['job-email-recipients', jobId],
    () => getEmailRecipients(jobId),
    {
      enabled,
      staleTime: Infinity,
    }
  );
};

const getSmsRecipients = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/sms_recipients/`
  );
  return data;
};

export const useSmsRecipientsChoices = (jobId: string, enabled = true) => {
  return useQuery(
    ['job-sms-recipients', jobId],
    () => getSmsRecipients(jobId),
    {
      enabled,
      staleTime: Infinity,
    }
  );
};

// ********** Get Contact field config **********
const getContactFieldConfig = async (job: string, q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${job}/contact_fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  contact?: string;
}

export const useContactFieldConfig = (job: string, q: IFieldConfig = {}) => {
  const { contact } = q;
  return useQuery(
    ['contact-field-config', { job, contact }],
    () => getContactFieldConfig(job, { contact }),
    {
      staleTime: contact ? 0 : Infinity,
    }
  );
};

// ****** Create contact *******
export const createContact = async (
  job: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${job}/contacts/`,
    payload
  );
  return data;
};

// ****** Update contact *******
export const updateContact = async (
  contact: string,
  job: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${job}/contacts/${contact}/`,
    payload
  );
  return data;
};

// ****** Delete contact ******
export const deleteContact = async (contact: string, job: string) => {
  await apiService.delete(`/api/light/jobs/${job}/contacts/${contact}/`);
};

// ********** Update Job **********
const getJobFieldConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/basic_fields_config/`
  );
  return data;
};

export const useJobFieldConfig = (jobId: string) => {
  return useQuery(['job-field-config', jobId], () => getJobFieldConfig(jobId), {
    staleTime: 0,
  });
};

export const updateBasicJobInfo = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/basic_info/`,
    payload
  );
  return data;
};

const getJobCustomFields = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/get_job_custom_fields/`
  );
  return data;
};

export const useJobCustomFields = (jobId: string) => {
  return useQuery(
    ['jobs', jobId, 'custom-fields'],
    () => getJobCustomFields(jobId),
    {
      staleTime: 0,
    }
  );
};

export const updateJobCustomFields = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/update_job_custom_fields/`,
    payload
  );
  return data;
};

export const updateCoverPhoto = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/set_cover_photo/`,
    payload
  );
  return data;
};

export const updateBidType = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/jobs/${jobId}/update_bid_type/`,
    payload
  );
  return data;
};

const getJobAssigneesFieldConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/assignees_fields_config/`
  );
  return data;
};

export const useJobAssigneesFieldConfig = (jobId: string) => {
  return useQuery(
    ['job-assignees-field-config', jobId],
    () => getJobAssigneesFieldConfig(jobId),
    {
      staleTime: 0,
    }
  );
};

export const updateJobAssignees = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/assignees/`,
    payload
  );
  return data;
};

const getCustomerFieldConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/customer_fields_config/`
  );
  return data;
};

export const useCustomerFieldConfig = (jobId: string) => {
  return useQuery(
    ['customer-field-config', jobId],
    () => getCustomerFieldConfig(jobId),
    {
      staleTime: 0,
    }
  );
};

export const updateCustomer = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/edit_customer/`,
    payload
  );
  return data;
};

export const updateCustomerCell = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/update_customer_cell/`,
    payload
  );
  return data;
};

export const updateCustomerEmail = async (jobId: string, email: string) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/update_customer_email/`,
    { email }
  );
  return data;
};

const getExpenseStats = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/expense_stats/`
  );
  return data;
};

export const useExpenseStats = (jobId: string) => {
  return useQuery(['job-expense-stats', jobId], () => getExpenseStats(jobId), {
    staleTime: 15 * 60 * 1000,
  });
};

// ***** checklist item related queries ************
const getVerifyLeadFieldConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/verify_lead_config/`
  );
  return data;
};

export const useVerifyLeadFieldConfig = (jobId: string) => {
  return useQuery(
    ['verify-lead', jobId],
    () => getVerifyLeadFieldConfig(jobId),
    {
      staleTime: 0,
    }
  );
};

const getRLTemplateChoices = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/rooflayout_template_choices/`
  );
  return data;
};

export const useRLTemplateChoices = (jobId: string) => {
  return useQuery(
    ['rl-template-choices', jobId],
    () => getRLTemplateChoices(jobId),
    { staleTime: Infinity }
  );
};

const getLightRLTemplateChoices = async (
  jobId: string,
  q?: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/light_rooflayout_template_choices/`,
    q
  );
  return data;
};

export const useLightRLTemplateChoices = (
  jobId: string,
  q?: Record<string, any>
) => {
  return useQuery(
    ['rl-light-template-choices', jobId],
    () => getLightRLTemplateChoices(jobId, q),
    { staleTime: Infinity }
  );
};

const getRLSupplierChoices = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/rooflayout_supplier_choices/`
  );
  return data;
};

export const useRLSupplierChoices = (jobId: string) => {
  return useQuery(
    ['rl-supplier-choices', jobId],
    () => getRLSupplierChoices(jobId),
    { staleTime: Infinity }
  );
};

const getScheduledInspectionConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/schedule_inspection_field_config/`
  );
  return data;
};

export const useScheduleInspectionConfig = (jobId: string) => {
  return useQuery(
    ['job-schedule-inspection', jobId],
    () => getScheduledInspectionConfig(jobId),
    { staleTime: 0 }
  );
};

const getAdjMtgConfig = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/schedule_adj_mtg_field_config/`
  );
  return data;
};

export const useAdjMtgConfig = (jobId: string) => {
  return useQuery(['job-adj-mtg', jobId], () => getAdjMtgConfig(jobId), {
    staleTime: 0,
  });
};

export const verifyLead = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/verify_lead/`,
    payload
  );
  return data;
};

export const unverifyLead = async (jobId: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${jobId}/unverify/`);
  return data;
};

export const scheduleInspection = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/schedule_inspection/`,
    payload
  );
  return data;
};

export const cancelInspection = async (jobId: string) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/cancel_inspection/`
  );
  return data;
};

export const completeInspection = async (jobId: string) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/complete_inspection/`
  );
  return data;
};

export const scheduleAdjMtg = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/schedule_adj_mtg/`,
    payload
  );
  return data;
};

export const cancelAdjMtg = async (jobId: string) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/cancel_adj_mtg/`
  );
  return data;
};

export const submit = async (jobId: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${jobId}/submit/`);
  return data;
};

export const unsubmit = async (jobId: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${jobId}/unsubmit/`);
  return data;
};

export const approve = async (jobId: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${jobId}/approve/`);
  return data;
};

export const editDateApproved = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/${jobId}/edit_date_approved/`,
    payload
  );
  return data;
};

export const reject = async (jobId: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${jobId}/reject/`);
  return data;
};

export const uploadPreContract = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/upload_pre_contract/`,
    payload
  );
  return data;
};

export const uploadContract = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/upload_contract/`,
    payload
  );
  return data;
};

const getCommunications = async ({
  pageParam = 1,
  jobId,
}: {
  pageParam: number;
  jobId: string;
}) => {
  let toExec = [
    getNotes({ pageParam, job: jobId }),
    getEmails({ pageParam, job: jobId }),
    getSmses({ pageParam, job: jobId }),
  ];

  if (pageParam === 1) {
    toExec = [...toExec, getJobTodos(jobId), getEvents(jobId)];
  }
  const [notes, emails, smses, ...args] = await Promise.all(toExec);
  const next_page = notes?.next_page || emails?.next_page || smses?.next_page;
  const count =
    (notes?.count || 0) +
    (emails?.count || 0) +
    (smses?.count || 0) +
    (args[0] || []).length +
    (args[1] || []).length;

  const results = [
    ...notes.results.map((r: any) => ({ ...r, _type: 'NOTE' })),
    ...emails.results.map((r: any) => ({ ...r, _type: 'EMAIL' })),
    ...smses.results.map((r: any) => ({ ...r, _type: 'SMS' })),
    ...(args[0] || []).map((r: any) => ({ ...r, _type: 'TODO' })),
    ...(args[1] || []).map((r: any) => ({ ...r, _type: 'EVENT' })),
  ];

  return {
    count,
    next_page,
    results: orderBy(
      results.map((r) => ({ ...r, _order: serverToUnix(r.date_created) })),
      ['_order'],
      ['desc']
    ),
  };
};

export const useCommunications = (jobId: string) => {
  return useInfiniteQuery(
    ['job-communications', jobId],
    ({ pageParam }) => getCommunications({ pageParam, jobId }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

const getEstimateLineitems = async (jobId: string, q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/lineitems/`,
    q
  );
  return data;
};

export const useEstimateAllLineitems = (
  jobId: string,
  q: Record<string, any>
) => {
  return useQuery(
    ['job-all-lineitems', jobId],
    () => getEstimateLineitems(jobId, q),
    {
      staleTime: 0,
    }
  );
};

export const getInvoiceLineitems = async (
  jobId: string,
  q?: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/lineitems_for_invoice/`,
    q
  );
  return data;
};

export const useInvoiceAllLineitems = (
  jobId: string,
  q?: Record<string, any>
) => {
  return useQuery(
    ['job-all-lineitems-invoice', jobId],
    () => getInvoiceLineitems(jobId, q),
    {
      staleTime: 0,
    }
  );
};

export const getInvoiceCreditLineitems = async (
  jobId: string,
  q?: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/lineitems_for_invoice_credit/`,
    q
  );
  return data;
};

export const useInvoiceCreditAllLineitems = (
  jobId: string,
  q?: Record<string, any>
) => {
  return useQuery(
    ['job-all-lineitems-invoice', 'credit', jobId],
    () => getInvoiceCreditLineitems(jobId, q),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );
};

export const requestPreSupplement = async (id: string) => {
  const { data } = await apiService.post(
    `/api/jobs/${id}/request_pre_supplement/`
  );
  return data;
};

export const releasePreSupplement = async (id: string) => {
  const { data } = await apiService.post(
    `/api/jobs/${id}/release_pre_supplement/`
  );
  return data;
};

const getJobListFilterFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/jobs/filter-list/fields_config/`,
    q
  );
  return data;
};

export const useJobListFilterFieldConfig = (q: Record<string, any> = {}) => {
  return useQuery(
    ['job-list-filters-field-config', q],
    () => getJobListFilterFieldConfig(q),
    {
      staleTime: q?.filter ? 0 : Infinity,
    }
  );
};

export const getJobListFilters = async (list_type = 'jobs') => {
  const { data } = await apiService.get(
    `/api/light/jobs/list_custom_filters/`,
    {
      list_type,
    }
  );
  return data;
};

export const useJobListFilters = (list_type = 'jobs') => {
  return useQuery(
    ['job-list-filters', list_type],
    () => getJobListFilters(list_type),
    {
      staleTime: 30 * 60 * 1000,
    }
  );
};

export const createJobListFilter = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/jobs/filter-list/`,
    payload
  );
  return data;
};

export const updateJobListFilter = async (
  id?: number,
  payload?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/jobs/filter-list/${id}/`,
    payload
  );
  return data;
};

export const deleteJobListFilter = async (id?: number) => {
  const { data } = await apiService.delete(
    `/api/light/jobs/filter-list/${id}/`
  );
  return data;
};

export const undoPreSupplementRequest = async (id: string) => {
  const { data } = await apiService.post(
    `/api/jobs/${id}/unrequest_pre_supplement/`
  );
  return data;
};

export const undoPreSupplementRelease = async (id: string) => {
  const { data } = await apiService.post(
    `/api/jobs/${id}/unrelease_pre_supplement/`
  );
  return data;
};

const getInspectionChoices = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/inspection_choices/`
  );
  return data;
};

export const useInspectionChoices = (jobId: string) => {
  return useQuery(
    ['inspection-choices', jobId],
    () => getInspectionChoices(jobId),
    { staleTime: Infinity }
  );
};

const getRoofBuildingChoices = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/roof_building_choices/`
  );
  return data;
};

export const useRoofBuildingChoices = (jobId: string) => {
  return useQuery(
    ['roof-building-choices', jobId],
    () => getRoofBuildingChoices(jobId),
    { staleTime: Infinity }
  );
};

const getRoofMeasuremntChoices = async (jobId: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${jobId}/roof_measurement_choices/`
  );
  return data;
};

export const useRoofMeasuremntChoices = (jobId: string) => {
  return useQuery(
    ['roof-measurement-choices', jobId],
    () => getRoofMeasuremntChoices(jobId),
    { staleTime: Infinity }
  );
};

export const getPOSearch = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/jobs/po/', payload);
  return data;
};

export const copyJob = async (
  id: number | string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(`/api/jobs/${id}/copy_job/`, payload);
  return data;
};

export const uploadCsv = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(`/api/utils/import_jobs/`, payload);
  return data;
};

export const addPaymentMethodForCustomer = async (
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/customer/payment-source/table/`,
    payload,
    q
  );
  return data;
};

export const getCustomerPaymentMethods = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get(
    '/api/light/customer-payment-methods/',
    {
      ...q,
      page: pageParam,
    }
  );
  return data;
};

export const useCustomerPaymentMethods = (
  q?: Record<string, any>,
  page = 1
) => {
  return useQuery(
    ['customer-payment-methods', q, { page }],
    () => getCustomerPaymentMethods({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

export const deletePaymentMethod = async (id: number) => {
  await apiService.delete(`/api/light/customer-payment-methods/${id}/`);
  return true;
};

export const makePaymentMethodDefault = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/customer-payment-methods/${id}/default/`
  );
  return data;
};

const getCustomerPaymentMethodChoices = async (id: number) => {
  const { data } = await apiService.get(
    `/api/light/customers/${id}/payment_methods/`
  );
  return data;
};

export const useCustomerPaymentMethodChoices = (id: number) => {
  return useQuery(
    ['customer-payment-methods', 'choices'],
    () => getCustomerPaymentMethodChoices(id),
    {
      staleTime: Infinity,
    }
  );
};

const getPaymentPermissions = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/payment_permissions/`
  );
  return data;
};

export const usePaymentPermissions = (id: string) => {
  return useQuery(
    ['job-detail', id, 'payment-permissions'],
    () => getPaymentPermissions(id),
    {
      staleTime: 15 * 60 * 1000,
    }
  );
};

const getDefaultWastePct = async (id: string, q?: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/jobs/${id}/get_default_waste_pct/`,
    q
  );
  return data;
};

export const useDefaultWastePct = (id: string, q?: Record<string, any>) => {
  return useQuery(
    ['job-detail', id, 'default-waste-pct'],
    () => getDefaultWastePct(id, q),
    {
      staleTime: 0,
    }
  );
};

export const migrateToMultiInvoice = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${id}/migrate_to_multi_invoice/`,
    payload
  );
  return data;
};

export const postCreateJobOps = async (id: string) => {
  await apiService.post(`/api/jobs/${id}/post_create_job_ops/`);
};

// Job checklist apis
export const setRoofScheduleDate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/set_roof_scheduled_date/`,
    payload
  );
  return data;
};

export const setRoofCompletedDate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/set_roof_completed_date/`,
    payload
  );
  return data;
};

export const setAllTradesScheduleDate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/set_all_trades_scheduled_date/`,
    payload
  );
  return data;
};

export const setAllTradesCompleteDate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/set_all_trades_completed_date/`,
    payload
  );
  return data;
};

export const closeJob = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/close/`,
    payload
  );
  return data;
};

export const reopenJob = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/jobs/approved/${id}/reopen/`,
    payload
  );
  return data;
};

export const updateLeadStatus = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(`/api/leadstatus/`, payload);
  return data;
};

export const denyClaim = async (id: string) => {
  const { data } = await apiService.patch(`/api/light/jobs/${id}/deny_claim/`);
  return data;
};

export const undoDenyClaim = async (id: string) => {
  const { data } = await apiService.patch(
    `/api/light/jobs/${id}/undo_deny_claim/`
  );
  return data;
};

export const approveClaim = async (id: string) => {
  const { data } = await apiService.patch(
    `/api/light/jobs/${id}/approve_claim/`
  );
  return data;
};

export const undoApproveClaim = async (id: string) => {
  const { data } = await apiService.patch(
    `/api/light/jobs/${id}/undo_approve_claim/`
  );
  return data;
};

export const softDeleteJob = async (id: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${id}/soft_delete/`);
  return data;
};

export const undeleteJob = async (id: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${id}/undelete/`);
  return data;
};

export const permDeleteJob = async (id: string) => {
  const { data } = await apiService.post(`/api/light/jobs/${id}/perm_delete/`);
  return data;
};

export const bulkDeleteJobs = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/jobs/unapproved/table/bulk_delete/`,
    payload
  );
  return data;
};

export const bulkPermanentDeleteJobs = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/jobs/unapproved/table/bulk_perm_delete/`,
    payload
  );
  return data;
};
